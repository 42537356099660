<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="UID" prop="id">
        <el-input placeholder="UID" v-model="searchForm.id"></el-input>
      </el-form-item>
      <el-form-item label="裁判名称" prop="name">
        <el-input placeholder="裁判名称" v-model="searchForm.name"></el-input>
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-select v-model="searchForm.status" clearable placeholder="请选择">
          <el-option value="-1" label="全部"></el-option>
          <el-option value="0" label="使用中"></el-option>
          <el-option value="1" label="回收站"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <perContainer :perm="`armory:${type}:save`">
        <el-form-item>
          <el-button type="primary" @click="add">新增</el-button>
        </el-form-item>
      </perContainer>
      <perContainer :perm="`armory:${type}:saverule`">
        <el-form-item>
          <el-button type="warning" @click="ruleVisible = true"
            >编辑规则</el-button
          >
        </el-form-item>
      </perContainer>
      <perContainer :perm="`armory:${type}:save`">
        <el-form-item>
          <el-button type="warning" @click="handleShowIntegralRule"
            >活动类型</el-button
          >
        </el-form-item>
      </perContainer>
      <perContainer :perm="`armory:${type}:importinfo`">
        <importButton :type="type" :sub_type="'info'"></importButton>
      </perContainer>
      <perContainer :perm="`armory:${type}:importinfo`">
        <importHistoryButton
          :type="type"
          :sub_type="'info'"
        ></importHistoryButton>
      </perContainer>
      <!--      <el-form-item>-->
      <!--        <el-button @click="handleReset()">重置</el-button>-->
      <!--      </el-form-item>-->
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          v-if="item.prop == 'avatar'"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <img
              v-if="scope.row.avatar && scope.row.avatar.length > 0"
              style="border-radius: 15px; width: 30px; height: 30px"
              :src="scope.row.avatar"
            />
            <div v-else style="color: #777777; width: 30px; text-align: center">
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.prop == 'title'"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <img
              v-if="scope.row.title && scope.row.title._id"
              style="width: 90px; height: 30px"
              :src="scope.row.title.img"
            />
            <div v-else style="color: #777777; width: 90px; text-align: center">
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <perButton
            :perm="`armory:${type}:list`"
            @click.native.stop="handlePreview(scope.row)"
            >预览</perButton
          >
          <perButton
            :perm="`armory:${type}:save`"
            @click.native.stop="handleEdit(scope.row)"
            >编辑裁判</perButton
          >
          <perButton
            :perm="`armory:${type}:changerank`"
            @click.native.stop="handleEditPoints(scope.row)"
            >编辑积分</perButton
          >
          <popconfirmButton
            v-if="scope.row.status != 1"
            :perm="`armory:${type}:save`"
            content="是否确认删除？"
            text="删除"
            @onConfirm="handleDelete(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
          <popconfirmButton
            v-else
            :perm="`armory:${type}:save`"
            content="是否确认恢复？"
            text="恢复"
            @onConfirm="handleRecovery(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>

    <integralRuleManager
      @onClose="integralRuleVisible = false"
      v-if="integralRuleVisible"
      :type="type"
    ></integralRuleManager>
    <ruleManager
      v-if="ruleVisible"
      :type="type"
      @onClose="ruleVisible = false"
    ></ruleManager>
    <integralEdit
      v-if="integralVisible"
      from="info"
      :subject_name="integralData.subject_name"
      :ref_id="integralData.ref_id"
      :season_id="integralData.season_id"
      @onClose="integralVisible = false"
    ></integralEdit>
    <preview
      v-if="previewVisible"
      :ref_id="previewRefId"
      @closed="onPreviewClosed"
    ></preview>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import perButton from "@/components/perm/perButton";
import integralRuleManager from "../components/integralRuleManager";
import integralEdit from "./integralEdit";
import ruleManager from "../components/ruleManager";
import importButton from "../components/importButton";
import importHistoryButton from "../components/importHistoryButton";
import preview from "./preview";
export default {
  name: "referee_list",
  components: {
    perButton,
    integralEdit,
    integralRuleManager,
    ruleManager,
    importButton,
    importHistoryButton,
    preview,
  },
  props: {},
  data() {
    let self = this;
    return {
      ids: [],
      type: "referee",
      loading: false,
      previewVisible: false,
      previewRefId: "",
      pageSize: 10,
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        id: "",
        name: "",
        status: "",
      },
      dataTable: [],
      dataList: [
        {
          prop: "uid",
          label: "UID",
          width: 300,
        },
        {
          prop: "name",
          label: "裁判名称",
          width: 300,
        },
        {
          prop: "avatar",
          label: "裁判头像",
          width: 200,
        },
        {
          prop: "title",
          label: "称号",
          width: 200,
        },
        {
          prop: "status",
          label: "状态",
          width: 80,
          formatter: (row) => {
            switch (row.status) {
              case 0:
                return "使用中";
              case 1:
                return "回收站";
              default:
                return "";
            }
          },
        },
      ],
      integralRuleVisible: false,
      integralVisible: false,
      integralData: {
        ref_id: "",
        subject_name: "",
        season_id: "",
      },
      ruleVisible: false,
    };
  },
  computed: {},
  methods: {
    onPreviewClosed() {
      this.previewVisible = false;
      this.previewRefId = "";
    },
    async handleShowIntegralRule() {
      this.integralRuleVisible = true;
    },
    add() {
      this.removeTabByPath(`/armory/${this.type}/add`);
      this.removeTabByPath(`/armory/${this.type}/edit`);
      this.$router.push({
        path: `/armory/${this.type}/add`,
      });
    },
    async handlePreview(row) {
      this.previewRefId = row._id;
      this.previewVisible = true;
    },
    async handleEdit(row) {
      this.removeTabByPath(`/armory/${this.type}/add`);
      this.removeTabByPath(`/armory/${this.type}/edit`);
      this.$router.push({
        path: `/armory/${this.type}/edit`,
        query: { _id: row._id },
      });
    },
    handleEditPoints(row) {
      this.integralData.ref_id = row._id;
      this.integralData.subject_name = row.name;
      this.integralVisible = true;
    },
    async handleDelete(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.armory.deleteInfo(
        row._id,
        this.type
      );
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "删除成功",
      });

      row.status = data.status;
    },
    async handleRecovery(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.armory.recovery(
        row._id,
        this.type
      );
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "恢复成功",
      });

      row.status = data.status;
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery();
    },
    async handleQuery() {
      const { pageNum } = this.pager;
      const { data, errorCode } = await this.$http.armory.list(this.type, {
        ...this.searchForm,
        pageNum,
        pageSize: this.pageSize,
      });
      if (errorCode != "0000") return;
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    onRefereeChanged(data) {
      let current = this.dataTable.find((r) => r._id == data._id);
      if (current) {
        current.uid = data.uid;
        current.name = data.name;
        current.avatar = data.avatar;
        current.title = data.title;
        current.status = data.status;
      } else {
        this.pager.pageNum = 1;
        this.handleQuery();
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async created() {
    this.handleQuery();
    this.$bus.$off("onRefereeChanged", this.onRefereeChanged);
    this.$bus.$on("onRefereeChanged", this.onRefereeChanged);
  },
  destroyed() {
    this.$bus.$off("onRefereeChanged", this.onRefereeChanged);
  },
};
</script>
<style lang="less">
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>
